<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="logo text-center">
          <img src="../assets/yellow.svg" />
        </div>
      </div>
      <div class="col">
        <div class="logo text-center mt-4">
          <h1>NFT Tools</h1>
          <h3>Metadata Generator</h3>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid text-center m-5"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      id="assetsFieldHandle"
      ref="file"
      type="file"
      multiple
      name="fields[assetsFieldHandle][]"
      class="d-none"
      accept=".pdf,.jpg,.jpeg,.png,.gif,.svg,.tiff,.bmp,.webp,.ico,.psd,.raw,.eps,.ai,.indd,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.odt,.ods,.odp,.odg,.odc,.odf,.odb,.rtf,.txt,.tex,.log,.csv,.tsv,.sql,.xml,.ini,.yml,.yaml,.json,.toml,.md,.markdown,.rst,.asciidoc,.adoc,.asc,.adoc,.ascx,.adc,.adcx,.ad"
      @change="onChange"
    />

    <label
      for="assetsFieldHandle"
      class="p-3 pt-5 pb-5 border border-primary bg-light cursor-pointer"
    >
      <div>
        Drag a file or
        <span class="underline">click here</span> to begin creating NFT metadata
      </div>
    </label>
    <div class="container">
      <div
        v-if="filelist.length && ready === filelist.length"
        v-cloak
        class="container row mt-4"
      >
        <div v-for="file in filelist" :key="file" class="col-sm-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="card-title">{{ file.name }}</div>
              <div class="card-subtitle text-muted">
                SHA512: {{ file.hash }}
              </div>
              <div class="card-body">
                <button
                  class="btn btn-primary btn-sm mb-3"
                  @click="meta(filelist.indexOf(file))"
                >
                  Metadata</button
                >&nbsp;
                <button
                  :class="
                    goodToGo(file) ? 'btn-secondary' : 'btn-outline-secondary'
                  "
                  class="btn btn-sm mb-3"
                  :disabled="!goodToGo(file)"
                  @click="generateJSON(file)"
                >
                  Generate JSON
                </button>
                <div></div>
                <button
                  type="button"
                  title="Remove file"
                  class="btn btn-danger btn-sm"
                  @click="remove(filelist.indexOf(file))"
                >
                  Remove &times;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    ref="modalResult"
    class="modal"
    :class="{ show: activeResult, 'd-block': activeResult }"
    tabindex="-1"
    role="dialog"
  >
    <div
      :class="{ show: activeResult, 'd-block': activeResult }"
      class="modal-dialog"
    >
      <div class="modal-content">
        <div class="modal-header">NFT JSON data</div>
        <div class="modal-body">
          <textarea
            ref="textToCopy"
            v-model="compiledString"
            class="results-TA"
            readonly
          ></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" @click="closeModal()">
            Close
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="copyToClipboard"
          >
            Copy to clipboard
          </button>
          <button type="button" class="btn btn-primary" @click="downloadJSON()">
            Download JSON
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    ref="modal"
    class="modal fade"
    :class="{ show: active, 'd-block': active }"
    tabindex="-1"
    role="dialog"
  >
    <div :class="{ show: active, 'd-block': active }" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ metadata.name }}</h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group mb-2">
            <label for="stats">Stats JSON</label>
            <textarea
              id="stats"
              v-model="stats"
              class="form-control"
              rows="10"
              @keyup="changeStats()"
            ></textarea>
            <!-- Unchecked -->
            <button v-if="checkStatus === 0" @click="checkStats()">
              Check
            </button>
            <span v-if="checkStatus === 0">
              &nbsp; Unchecked
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 9v2m0 4v.01" />
                <path
                  d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                />
              </svg>
            </span>
            <!-- Checking now... -->
            <span v-if="checkStatus === 1">
              <div class="spinner-border" role="status"></div>
              &nbsp; Checking JSON...
            </span>

            <!-- Checked: Error -->
            <span v-if="checkStatus === 2">
              &nbsp; Error in JSON
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 9v2m0 4v.01" />
                <path
                  d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                />
              </svg>
            </span>
            <!-- Checked: Success -->
            <span v-if="checkStatus === 3">
              &nbsp; Valid JSON
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </span>
          </div>
          <div class="form-group mb-2">
            <label for="image">Filetype</label>
            <input
              id="filetype"
              v-model="metadata.filetype"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group mb-5">
            <label for="provider">
              Provider address
              <svg
                v-if="!provider || !validProvider()"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 9v2m0 4v.01" />
                <path
                  d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                />
              </svg>
              <span v-if="!provider">Missing</span
              ><span v-if="provider && !validProvider()">Invalid</span>
              <svg
                v-if="provider && validProvider()"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
              <span v-if="provider && validProvider()"> Valid QRL address</span>
            </label>
            <input
              id="provider"
              v-model="provider"
              type="text"
              class="form-control"
              placeholder="Q010500..."
            />
          </div>
          <div class="form-group mb-2">
            <label for="fileHash">File hash</label>
            <textarea
              id="fileHash"
              v-model="metadata.hash"
              class="form-control"
              rows="3"
              disabled
            ></textarea>
          </div>
          <div class="form-group">
            <label for="metahash">Metahash</label>
            <textarea
              id="metahash"
              v-model="metahash"
              class="form-control"
              rows="3"
              disabled
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" @click="closeModal()">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="checkStatus !== 3"
            @click="saveAndCloseModal()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="active || activeResult" class="modal-backdrop fade show"></div>
</template>

<script>
import CryptoJS from 'crypto-js';
import jsonStringify from 'json-stable-stringify';
import validateQRLaddress from '@theqrl/validate-qrl-address';

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      const resultWordArray = CryptoJS.lib.WordArray.create(reader.result);
      const fileHash = CryptoJS.SHA512(resultWordArray).toString(
        CryptoJS.enc.Hex
      );
      resolve(fileHash);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export default {
  data() {
    // checkStatus: 0: not checked, 1: checking, 2: error, 3: success
    return {
      checkStatus: 0,
      filelist: [],
      index: -1,
      ready: 0,
      compiled: {},
      compiledString: '',
      active: false,
      activeResult: false,
      stats: '',
      metahash: null,
      provider: null,
      metadata: {
        name: null,
        metahash: null,
        hash: null,
        provider: null,
        type: 'cows',
        filetype: null,
        stats: {},
      },
    };
  },
  methods: {
    validProvider() {
      try {
        return validateQRLaddress.hexString(this.provider).result;
      } catch (e) {
        return false;
      }
    },
    downloadJSON() {
      const walletJson = this.compiledString;
      const binBlob = new Blob([walletJson]);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(binBlob, {
        type: 'text/plain',
      });
      const x = this.compiled.metahash;
      a.download = `nft-${x.slice(x.length - 5, x.length)}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async copyToClipboard() {
      const shareData = {
        title: 'QRL NFT Tools',
        text: this.compiledString,
        url: 'https://nft-tools.theqrl.org',
      };
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.log('Could not copy -- trying clipboard fallback');
        this.$refs.textToCopy.focus();
        this.$refs.textToCopy.select();
        document.execCommand('copy');
      }
    },
    generateJSON(file) {
      // console.log('Generate JSON for...');
      // console.log(file);
      this.compiled = {
        filehash: file.hash,
        metadata: {
          filetype: file.type,
          stats: file.stats,
        },
        metahash: file.metahash,
        provider: file.provider,
        standard: 1,
      };
      this.compiledString = JSON.stringify(this.compiled, null, 2);
      this.activeResult = true;
    },
    goodToGo(file) {
      if (!file.provider) {
        return false;
      }
      if (!file.metahash) {
        return false;
      }
      return true;
    },
    async checkStats() {
      this.checkStatus = 1;
      try {
        JSON.parse(this.stats);
      } catch (e) {
        this.checkStatus = 2;
      }
      if (this.checkStatus === 2) {
        return;
      }
      const response = await fetch('https://nft-linter.theqrl.org/lint-stats', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: this.stats,
      });
      const text = await response.text();
      const jsonR = JSON.parse(text);
      if (jsonR.valid) {
        this.checkStatus = 3;
        this.stats = jsonR.linted;
        const metadata = {
          filetype: this.metadata.filetype,
          stats: JSON.parse(this.stats),
        };
        console.log('metadata:', metadata);
        const jsonStrMetadata = jsonStringify(metadata);
        const metahash = CryptoJS.SHA512(jsonStrMetadata).toString(
          CryptoJS.enc.Hex
        );
        this.metahash = metahash;
      } else {
        this.checkStatus = 2;
      }
    },
    changeStats() {
      this.checkStatus = 0;
      this.metahash = null;
      try {
        const parsedStats = JSON.parse(this.stats);
      } catch (e) {
        this.checkStatus = 2;
      }
    },
    meta(index) {
      this.metadata = this.filelist[index];
      this.stats = JSON.stringify(this.metadata.stats, null, 2);
      this.index = index;
      this.checkStatus = 0;
      this.provider = this.metadata.provider;
      this.metahash = this.metadata.metahash;
      this.active = true;
    },
    saveAndCloseModal() {
      try {
        const parsedStats = JSON.parse(this.stats);
        this.filelist[this.index].stats = parsedStats;
        this.filelist[this.index].metahash = this.metahash;
        this.filelist[this.index].provider = this.provider;
        this.index = -1;
        this.active = false;
      } catch (e) {
        console.log('Invalid JSON... fix this before saving');
      }
    },
    closeModal() {
      this.active = false;
      this.activeResult = false;
    },
    async onChange() {
      const fl = [...this.$refs.file.files];
      Object.keys(fl).forEach(async (i) => {
        const file = fl[i];
        const hash = await readFileAsync(file);
        fl[i].hash = hash;
        fl[i].stats = { foo: 'bar', baz: 42 };
        if (fl[i].type === '') {
          fl[i].filetype = 'application/octet-stream';
        } else {
          fl[i].filetype = fl[i].type;
        }
        this.ready += 1;
      });
      this.filelist = [...fl, ...this.filelist];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.ready -= 1;
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-success')) {
        event.currentTarget.classList.add('bg-success');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('bg-success');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.remove('bg-success');
    },
  },
};
</script>

<style scoped>
.results-TA {
  height: 200px;
  width: 100%;
}
</style>
